@media screen and (max-width: 992px) {
  /* ---------Moblie Menu------------- */
  .small {
    display: block;
    transition: all 0.3s ease-in-out;
  }
  
  .small nav {
    padding: 15px 0;
  }
  
  .small nav .container-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .small nav .menu {
    width: 40px;
    height: 40px;
    background: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .small nav .sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 260px;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
    z-index: 1000;
  }
  
  .small.show .sidebar {
    right: 0;

  } 
  .small nav .sidebar>ul {
    margin-top: 70px !important;
  }

  .small nav .sidebar>ul>li {
    padding: 10px;
   
  }
  .small nav .sidebar ul>li{
    border-bottom: 1px solid #ece4e4;
  }
  .small nav .sidebar ul>li:last-child {
    border-bottom: 0px;
  }
  .small nav .sidebar ul>li:last-child {
    border-bottom: none;
  }
  
  .small nav .sidebar ul>li>a {
    color: #000;
    padding: 4px;
    font-size: 17px;
    text-transform: capitalize;
  }
  
  .small nav .sidebar>ul>li.link-sm {
    position: relative;
  }
  
  .small nav .sidebar ul li.link-sm i {
    position: absolute;
    top: 3px;
    right: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 19px;
    color: #1e1e1e;
    font-weight: 700;
  }
  
  .small nav .sidebar ul.sub-menu-sm {
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    margin-top: 0px !IMPORTANT;
  }
  
  .small nav .sidebar ul.sub-menu-sm li {
    padding: 4px 16px;
  }
  
  .small .times {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #0e0e0e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
  
  .small .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .small.show .overlay {
    opacity: 1;
    pointer-events: all;
  }
  
  .small {
    display: block;
  }
/* ---------Moblie Menu------------- */
/* ---------Moblie Call Action------------- */
.mobile-fixed-button{
  position: fixed;
  bottom: 0;
  width: 100%;
}
.call-btn{
  background-color: #000;
  padding: 15px 50px;
  text-transform: uppercase;
  color: #fff;
  display: block;
  text-align: center;
}
.mobile-fixed-button{
  display: none;
}
/* ---------Moblie Call Action------------- */
.top_ber{
  display: none;
}
.featured_service_sec .sevice_card .top_img .service_ul {
	position: absolute;
	top: -120%;
	left: 0;
	right: 0;
	padding: 15px;
	background-color: #00000073;
	color: #fff;
	width: 100%;
	height: 100%;
	transition: all 0.6s;
}
.heading h2 {
	font-size: 35px;
	margin-bottom: 15px;
	font-weight: 400;
}
h1 {
	font-size: 40px;
}

.banner {
	background: url('../../../public/assets/image/banner.webp') no-repeat center;
	background-size: cover;
	padding: 53px 0 20px;
	color: #fff;
  text-align: center;
}
.mobile-menu-top {
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: -40px;
}
.banner .banner_heading h1 .h1_sub {
  font-weight: 200;
  font-size: 40px;
}
section {
  padding: 50px 0px;
}
.showcasing_content  .heading {
  height: 100%;
  width: 100%;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
}
.testimonials_sec .testimonials_content .contented {
  border: 3px solid rgba(0, 0, 0, 0.26);
  padding: 15px;
  position: relative;
  z-index: 1;
  background-color: #00000054;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.testimonials_sec .testimonials_content .contented .coma_sty {
  margin-bottom: 8px;
  position: relative;
  margin-top: -50px;
  width: 67px;
}
.testimonials_sec .testimonials_content .content_profile {
  position: relative;
  z-index: 1;
  margin-top: -75px;
}
.testimonials_sec .testimonials_content .content_profile img {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50px;
  overflow: hidden;
}
.testimonials_sec .testimonials_content .contented .coma_sty {
  margin-bottom: 8px;
  position: relative;
  margin-top: -50px;
  width: 60px;
}
.showcasing_slider button.owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.showcasing_slider button.owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.adret_sec .heading{
  text-align: center;
}
.adret_sec .content h5 {
  font-size: 16px;
}
.adret_sec .content h5 br{
  display: none;
}
.home_contact .container .contact-form {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  margin-right: 0;
}
.home_contact .contact-form {
  background-color: #0b3b67;
  padding: 40px;
  text-align: center;
  height: 100%;
}
.sevice_card .content_left {
  padding: 15px;
}
.adret_sec .top_img {
  width: 60px;
  height: 60px;
  background-color: initial;
  border: 1px solid #11589a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
  margin-bottom: 10px !important;
}
.adret_sec .content {
 text-align: center;
}
.adret_sec .top_img {
  width: 60px;
  height: 60px;
  background-color: initial;
  border: 1px solid #11589a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
  margin-bottom: 10px !important;
  justify-content: center;
  margin: 0 auto;
}
.home_contact .container .contact-form {
  position: initial;
  right: 0;
  top: 0;
  width: 100%;
  margin-right: 0;
}
.map{
  line-height: 0;
}
.map iframe {
width: 100%;
height: 300px;

}
.review_slider button.owl-next {
  position: absolute;
  right: 0;
  top: 58%;
  transform: translateY(-50%);
}
.review_slider button.owl-prev {
  position: absolute;
  left: 0;
  top: 58%;
  transform: translateY(-50%);
}

footer {
  padding: 60px 0 50px;
  background-color: #000000;
  color: #8f8f8f;
}
footer .ft_content{
  text-align: center;
}
footer .ft_links2 li {
  margin-bottom: 8px;
  display: flex;
  align-items: self-start;
  color: #8f8f8f;
  justify-content: center;
}
footer .ft_links2 li:first-child i.fa-globe{
  margin-left: 15px;
}
footer .ft_socail {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.copy_txt p {
  margin-top: 17px;
  text-align: center;
}
footer ul.list-unstyled.ft_links li {
  margin: 0 12px;
  margin-bottom: 8px;
  display: inline-block;
}
.call-btn {
  background-color: #0272aa;
  padding: 7px 10px;
  text-transform: capitalize;
  color: #fff;
  display: block;
  text-align: center;
  font-size: 15px;
  width: 200px;
  margin: 0 auto;
  border-radius: 5px 5px 0 0;
  display:  flex;
  align-items: center;
  justify-content: center;
}
.call-btn img{
  width: 35px;
  margin-right: 10px;
  border-radius: 5px;
}
.home_contact .contact-form {
  background-color: #0b3b67;
  padding: 30px;
  text-align: center;
  height: 100%;
  position: initial;
  top: 0;
  right: 0;
  margin-right: 0;
  width: 100%;
  /* margin-bottom: 50px; */
}
.map {
  line-height: 0;
  margin-top: 55px;
}
.mobile-fixed-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 111;
}
.offcanvas {

  background: #041728;
  
}
.mobile-menu-top img {
  width: 125px;
  position: relative;
  margin-top: -57px;
  cursor: pointer;
}
.offcanvas-menu > ul > li {
  border-bottom: 1px solid #5d5d5d57 !important;
}
.logo_design_sec1 {
  text-align: center;
}
.logo_design_project .heading {
  background-color: #11589a;
  padding: 25px;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 11;
}
.logo_design_project .heading {
  background-color: #11589a;
  padding: 25px;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 11;
  text-align: center;
}
.logo_design_project .heading .btn_sec {
  margin-top: 15px;
}
.logo_design_sec .content_why {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin: 17px 0 0px;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
}
#faq_sec .accordion .accordion__title {
  font-size: 17px !important;
}
.accordion__title {
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
  color: rgb(255 255 255);
  font-weight: 400;
  padding: 10px 37px 10px 10px;
  font-size: 26px !important;
  margin-bottom: 0 !important;
  background-color: #11589a;
}
.bg-dotted {
  margin-top: 80px;
}

.mobile-friendly-image::after {
  display: none;
}
.inner-page {
  padding: 190px 0 40px;
}
h2 br{
  display: none;
}
h3 br{
  display: none;
}
#about-bg {
  background: url('../../../public/assets/image/about-banner.png') no-repeat center;
  background-size: cover;
  background-position: 68% 50%;
}
}

@media screen and (max-width: 767px) {
  .mobile-friendly-image::after {
    left: -35px;
  }
}

@media screen and (max-width: 567px) {
  .mobile-fixed-button{
    display: block;
  }
  .home_contact .contact-form {
    background-color: #0b3b67;
    padding: 30px 15px;
    text-align: center;
    height: 100%;
}
h4 {
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 300;
}
.map {
  line-height: 0;
  margin-top: 45px;
}
  .heading h2 {
    font-size: 27px;
    margin-bottom: 10px;
    font-weight: 400;
}
  .featured_service_sec .service_ul li {
    font-size: 13px;
    margin-bottom: 4px;
  }
  .featured_service_sec .sevice_card .top_img .service_ul {
    position: absolute;
    top: -120%;
    left: 0;
    right: 0;
    padding: 12px;
    background-color: #00000073;
    color: #fff;
    width: 100%;
    height: 100%;
    transition: all 0.6s;
  }

  h1 {
    font-size: 30px;
}
.want_work_sec {
  background: url('../../../public/assets/image/want-work-bg-sm.jpg') no-repeat center;
  background-size: cover;
  padding: 45px 0 316px;
}
.want_work_sec .ul_con li {
  display: inline-block;
  width: 250px;
  margin-bottom: 4px;
}
.banner {
  background:linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.5)), url('../../../public/assets/image/banner-sm.jpg') no-repeat center;
  background-size: cover;
  padding: 45px 0 95px;
}
.nav-pills .nav-link {
  border-radius: 0px;
  width: 173px;
  padding: 10px 2px;
  border: 1px solid #11589a;
  font-weight: 500;
  font-size: 15px;
}
.btn_gap {
  margin-right: 0px;
  margin-bottom: 10px;
}
.logo_design_business {
  background:linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url('../../../public/assets/image/logo-design-bg1.jpg') no-repeat center;
  background-size: cover;
  color: #fff;
  text-align: center;
}
.logo_design_best iframe {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 190px;
}
.logo_design_project .heading::before {
  content: "";
  position: initial;
  background: initial;
  left: -30%;
  top: 0px;
  clip-path: polygon(31% 0, 100% 0, 100% 100%, 77% 100%);
  z-index: 1;
  width: initial;
  height: 100%;
}
.logo_design_project .heading {
  background-color: #11589a;
  padding: 25px;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 11;
}
.porcese_ber .porcess_con {
  width: initial;
  text-align: center;
}
.porcese_ber {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  gap: 30px;
}
.porcess_con .porcess_icon::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 122px;
  background-color: #11589a;
}
}

@media screen and (max-width: 370px) {
  .nav-pills .nav-link {
    border-radius: 0px;
    width: 145px;
    padding: 10px 2px;
    border: 1px solid #11589a;
    font-weight: 500;
    font-size: 14px;
  }
}